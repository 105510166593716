import React from "react";
import ProductLanding from './all-home-pages/ProductLanding';

const PreviewMain = () => {
  return (
    <div>
      <ProductLanding />
    </div>
  );
};

export default PreviewMain;
