import React from "react";

// Main Preview Pages
import PreviewMain from "../views/PreviewMain";

// All HOME PAGE ROUTES
import ProductLanding from "../views/all-home-pages/ProductLanding";

// Not Found Page
import NotFound from "../views/NotFound";

// Route Specific
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ScrollTopBehaviour from "../components/ScrollTopBehaviour";

const Routes = () => {
  return (
    <>
      <Router>
        <ScrollTopBehaviour />
        <Switch>
          <Route exact path="/" component={PreviewMain} />
          <Route path="/product-landing" component={ProductLanding} />
          <Route path="/404" component={NotFound} />
          {/* NotFound Route */}
          <Route component={PreviewMain} />
        </Switch>
      </Router>
    </>
  );
};

export default Routes;
