import React from "react";

const PricingContent = [
  {
    packName: "Core",
    packDetails: "For gardens and small farms",
    bgColor: "#FFF7EB",
    price: "0",
    durationNumber: "per month",
    facility: "Free Forever",
    features: [
      "Unlimited Custom Varieties",
      "Add up to 25 Plantings",
    ],
    animationDelay: "",
    activeClass: "",
  },
  {
    packName: "Pro",
    packDetails: "For large farms",
    bgColor: "#E2F2FD",
    price: "9",
    durationNumber: "per month",
    facility: "7 day free trial",
    features: [
      "Unlimited Custom Varieties",
      "Unlimited Season Plans",
      "Access to 750+ Presets",
      "Priority Support",
      "Printable Plans"
    ],
    animationDelay: "100",
    activeClass: "active",
  },
];

const PricingMonthly = () => {
  return (
    <div className="row justify-content-center">
      {PricingContent.map((val, i) => (
        <div
          className="col-lg-4 col-sm-6"
          data-aos="fade-up"
          data-aos-duration="1200"
          data-aos-delay={val.animationDelay}
          key={i}
        >
          <div className={`pr-table-wrapper ${val.activeClass}`}>
            <div className="pack-name">{val.packName}</div>
            <div className="pack-details">{val.packDetails}</div>
            <div
              className="top-banner d-md-flex"
              style={{ background: val.bgColor }}
            >
              <div className="price">
                <sup>$</sup>
                {val.price}
              </div>
              <div>
                <span>{val.durationNumber}</span>
                <em>{val.facility}</em>
              </div>
            </div>
            {/* /.top-banner */}
            <ul className="pr-feature">
              {val.features.map((list, i) => (
                <li key={i}>{list}</li>
              ))}
            </ul>
            <a href="https://app.bloommanager.com" className="trial-button">
              Get Started
            </a>
            <div className="trial-text">Cancel any time</div>
          </div>
          {/* /.pr-table-wrapper */}
        </div>
      ))}
    </div>
  );
};

export default PricingMonthly;
