import React from "react";
import { Link } from "react-router-dom";

const FeaturesContent = [
  {
    bgColor: "#FFEBDB",
    icon: "67",
    title: "Automatic Plan Generation",
    desc: `Enjoy picking out varieties, and let Bloom Manager calculate the best time to seed, pinch, pot on, harden off, transplant and harvest your chosen varieties.`,
    dataDealy: "0",
  },
  {
    bgColor: "#E0F8F8",
    icon: "67",
    title: "Completely Customizable",
    desc: `Every growing area is different. That's why Bloom Manager gives you full freedom to adjust each and every growing parameter of each cut flower variety.`,
    dataDealy: "100",
  },
  {
    bgColor: "#F7EDFF",
    icon: "70",
    title: "Visual Planner",
    desc: `Bloom Manager makes planning your flower farm visual. You literally draw your plantings and they're added to your plan - like magic!`,
    dataDealy: "200",
  },
];

const FancyFeatureTen = () => {
  return (
    <div className="row justify-content-center mt-35 md-mt-20">
      {FeaturesContent.map((val, i) => (
        <div
          className="col-lg-4 col-md-6"
          data-aos="fade-up"
          data-aos-duration="1200"
          data-aos-delay={val.dataDealy}
          key={i}
        >
          <div className="block-style-fifteen mt-40">
            <div
              className="icon d-flex align-items-center justify-content-center"
              style={{ background: val.bgColor }}
            >
              <img src={`images/icon/${val.icon}.svg`} alt="icon" />
            </div>
            <h3>{val.title}</h3>
            <p>{val.desc}</p>
            <a href="https://app.bloommanager.com" className="mt-30">
              {""}
              <img src="images/icon/69.svg" alt="icon" />
            </a>
          </div>
          {/* /.block-style-ten */}
        </div>
      ))}
    </div>
  );
};

export default FancyFeatureTen;
