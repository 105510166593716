import React from "react";
import { Link } from "react-router-dom";

const FancyFeatureEleven = () => {
  return (
    <>
      <div className="block-style-sixteen" style={{ background: "#EBF3F1" }}>
        <div className="container">
          <div className="row">
            <div
              className="col-xl-5 col-lg-6"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              <div
                className="icon d-flex align-items-center justify-content-center"
                style={{ background: "#DEEAE7" }}
              >
                <img src="images/icon/73.svg" alt="icon" />
              </div>
              <h3 className="title">Pick what you're growing - and where.</h3>
              <p>
                Use Visual Planner and choose from hundreds of cut flower varieties (or create your own) to add to your flower farm plan. Plus, decide on options like pinching or succession planting.
              </p>
              <a href="https://app.bloommanager.com">
                <img src="images/icon/72.svg" alt="icon" />
              </a>
            </div>
          </div>
        </div>
        <img
          src="images/assets/landing-new.gif"
          style={{borderRadius: 20}}
          alt=""
          className="shapes screen-one"
        />
      </div>
      {/* /.block-style-sixteen */}

      <div className="block-style-sixteen" style={{ background: "#EDF3F9" }}>
        <div className="container">
          <div className="row">
            <div
              className="col-xl-5 col-lg-6 ml-auto"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <div
                className="icon d-flex align-items-center justify-content-center"
                style={{ background: "#E1E7ED" }}
              >
                <img src="images/icon/74.svg" alt="icon" />
              </div>
              <h3 className="title">Generate tasks & adjust your harvest schedule.</h3>
              <p>
                Review and adjust your automatically generated <b>Flower Farm Plan</b> based on predicted harvest dates. Easy-to-use calendar and timeline interfaces let you identify gaps in your season and ensure optimal bloom times.
              </p>
              <a href="https://app.bloommanager.com">
                <img src="images/icon/72.svg" alt="icon" />
              </a>
            </div>
          </div>
        </div>
        <img
          src="images/assets/calendar.png"
          style={{borderRadius: 20}}
          alt="screen"
          className="shapes screen-two"
        />
      </div>
      {/* /.block-style-sixteen */}

      <div className="block-style-sixteen" style={{ background: "#F9F1EF" }}>
        <div className="container">
          <div className="row">
            <div
              className="col-xl-5 col-lg-6"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              <div
                className="icon d-flex align-items-center justify-content-center"
                style={{ background: "#EDE5E3" }}
              >
                <img src="images/icon/75.svg" alt="icon" />
              </div>
              <h3 className="title">Keep on top of everyday growing tasks.</h3>
              <p>
                Bloom Manager makes it easy to stay up-to-date with flower farm growing tasks throughout the season, complete with variety-specific notes and guidance as you grow.
              </p>
              <a href="https://app.bloommanager.com">
                <img src="images/icon/72.svg" alt="icon" />
              </a>
            </div>
          </div>
        </div>
        <img
          src="images/assets/list-new.png"
          style={{borderRadius: 20}}
          alt="screen"
          className="shapes screen-one"
        />
      </div>
      {/*  /.block-style-sixteen */}
    </>
  );
};

export default FancyFeatureEleven;
