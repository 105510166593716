import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Routes from "./router/Routes";
import ScrollToTop from "./components/ScrollToTop";
import AOS from "aos";
import "aos/dist/aos.css";

const App = () => {
  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
  }, []);
  window.addEventListener("load", AOS.refresh);

  return (
    <>
      <Helmet>
        <title>Bloom Manager - Flower Farm Season Planning Platform</title>
        <meta property="og:site_name" content="Bloom Manager" />
        <meta
          property="og:url"
          content="https://bloommanager.com"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Bloom Manager - Flower Farm Season Planning Platform"
        />
        <meta
          name="keywords"
          content="flower farm, cut flower farm, flower farmer, plan flower farm, flower farm layout planner, flower garden planner, how to plan a flower farm, flower farm software, when to start flowers indoors"
        />
        <meta
          name="description"
          content="Bloom Manager is a free planning tool for flower farmers that automatically generates your season plan. "
        />
        <meta name="description" content="Flower Farm Season Planning Tool" />
      </Helmet>
      {/* End Seo Helmt */}

      <ScrollToTop />
      <Routes />
    </>
  );
};

export default App;
