import React, { useState } from "react";
import BrandTwo from "../brand/BrandTwo";
import Modal from "react-modal";
import HeaderPopupForm from "../form/HeaderPopupForm";

Modal.setAppElement("#root");

const HeroBannerFive = () => {
  const [isOpen, setIsOpen] = useState(false);
  function toggleModalOne() {
    setIsOpen(!isOpen);
  }

  return (
    <div className="hero-banner-five">
      <div className="container">
        <div className="row">
          <div className="col-xl-10 col-lg-11 col-md-10 m-auto">
            <h1
              className="font-recoleta hero-heading"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              We plan. You <span>Grow.</span>
            </h1>
            <p
              className="hero-sub-heading"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="100"
            >
              Bloom Manager is a free all-in-one season planning platform for flower farmers and gardeners.
            </p>
          </div>
        </div>
        {/* End .row */}

        <div className="d-sm-flex align-items-center justify-content-center button-group">
          <a
            href="https://app.bloommanager.com"
            className="d-flex align-items-center ios-button"
            data-aos="fade-up"
            data-aos-duration="1200"
            data-aos-delay="200"
          >
            <img src="images/icon/80.svg" alt="brand" className="icon" />
            <div>
              <span>Compatible with</span>
              <strong>Everything</strong>
            </div>
          </a>
        </div>
        {/* End button group */}

        <p
          className="sing-in-call"
          data-aos="fade-up"
          data-aos-duration="1200"
          data-aos-delay="400"
        >
          Works on any device with a web browser
        </p>
        {/* End sign-in-call */}
      </div>
      {/* End .container */}

      <div
        className="img-gallery"
        data-aos="fade-up"
        data-aos-duration="1200"
        data-aos-delay="400"
      >
        <div className="container text-center">
          <div className="screen-container">
            <img
              src="images/assets/calendar.png"
              alt="shape"
              className="main-screen"
            />
            <img
              src="images/assets/screen_01.1.png"
              alt="shape"
              className="shapes screen-one"
            />

          </div>
          {/* /.screen-container */}
        </div>
      </div>
      {/* /.img-gallery */}

      <img
        src="images/shape/133.svg"
        alt="shape"
        className="shapes shape-one"
      />
      <img
        src="images/shape/134.svg"
        alt="shape"
        className="shapes shape-two"
      />
      <img
        src="images/shape/135.svg"
        alt="shape"
        className="shapes shape-three"
      />
      <img
        src="images/shape/136.svg"
        alt="shape"
        className="shapes shape-four"
      />

      <div className="partner-slider-two mt-110 md-mt-80">
        <div className="container">
          <p className="text-center">
            <span>2000+</span> small flower farms are planning with Bloom Manager. <b>Will yours be next?</b>
          </p>
          {/* End .partner slider two */}
        </div>
      </div>
      {/* /.partner-slider-two */}
    </div>
    // /.hero-banner-five
  );
};

export default HeroBannerFive;
